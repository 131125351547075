import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./NavBar.css";

export default function NavBar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <button className="hamburger" onClick={toggleMenu}>
        &#9776;
      </button>
      <NavLink to={"/"}>
        <div className="logo">
          <img src={require("../images/logoEdivolt.png")} alt="app logo" />
        </div>
      </NavLink>

      <div className="nav-content">
        <ul
          onClick={toggleMenuClose}
          className={`nav-links ${isMenuOpen ? "open" : ""}`}
        >
          <button className="close-icon" onClick={toggleMenuClose}>
            &times;
          </button>
          <li className="nav-item">
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
            >
              Home
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
            >
              About Us
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink
              to="/benefits"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
            >
              Solutions
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink
              to="/investors"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
            >
              Investors
            </NavLink>
          </li>

          {/* <li className="nav-item">
            <NavLink
              to="/blog"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
            >
              Blog
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? "nav-link active-link" : "nav-link"
              }
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </div>

      <NavLink to={"./Pre-Order"}>
        <button className="register-button">Pre-Order</button>
      </NavLink>
    </nav>
  );
}

