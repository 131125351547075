import React from "react";
import "./Shape.css";

const Shapes = () => {
  return (
    <div className="shape-container">
      {[...Array(7)].map((_, index) => (
        <div key={index} className="big-shape"></div>
      ))}
    </div>
  );
};

export default Shapes;

