import React from "react";
import "./FeaturedStyles.css";
import { GiRecycle } from "react-icons/gi";
import { AiOutlineMobile } from "react-icons/ai";
import { MdLightbulbOutline } from "react-icons/md";

const FeaturedSection = () => {
  return (
    <div className="featured-section">
      <div className="shapes-container">
        {/* Decorative shapes */}
        <div className="shape rectangle-1"></div>
        <div className="shape rectangle-2"></div>
        <div className="shape rectangle-3"></div>
        <div className="shape rectangle-4"></div>
        <div className="shape rectangle-5"></div>
        <div className="shape rectangle-6"></div>
        <div className="shape rectangle-7"></div>
        <div className="shape circle-1"></div>
        <div className="shape circle-2"></div>
        <div className="shape triangle-1"></div>
      </div>

      <div className="featured-big-container">
        <div>
          <h2 className="featured-heading">Why Choose Eddievolt?</h2>
          <p className="featured-description">
            Discover the core pillars that make Eddievolt the leading choice for
            secure, convenient, and sustainable charging solutions.
          </p>
          <div className="feature-list">
            <div className="top-feat">
              <div className="feature-item">
                <MdLightbulbOutline className="featured-icon" />
                <h3 className="feature-title">Innovation</h3>
                <p className="feature-description">
                  Our charging stations feature advanced technology, including
                  solar power, robust encryption for data security, real-time
                  monitoring systems, and integrated advertising display
                  screens.
                </p>
              </div>
              <div className="feature-item">
                <AiOutlineMobile className="featured-icon" />

                <h3 className="feature-title">Convenience</h3>

                <p className="feature-description">
                  Strategically located in high-traffic urban areas, our
                  charging stations provide easy access to secure charging
                  wherever you are, with additional value through targeted
                  advertising.
                </p>
              </div>
            </div>
            <div className="last-feature feature-item ">
              <GiRecycle className="featured-icon" />
              <div></div>
              <div>
                <h3 className="feature-title">Sustainability</h3>
                <p className="feature-description">
                  Powered by the sun, Eddievolt reduces environmental impact and
                  contributes to a greener future.
                </p>
              </div>
            </div>
          </div>
        </div>
        <img
          className="featured-image"
          src={require("../images/eddievolt-bus-station-hub.jpeg")}
          alt=""
        />
      </div>
    </div>
  );
};

export default FeaturedSection;

