import React, { useState } from "react";
import "./Testimonials.css";
import Shapes from "./Shape";

const testimonialData = [
  {
    feedBack:
      "I was initially skeptical, but after using the Eddievolt charging station at Accra Mall, I was thoroughly impressed. The convenience of being able to securely charge my phone while shopping, along with the seamless connection to WiFi, made a huge difference in my day. Ghana needs more of these!",
    name: "Kwame Mensah, Pilot User at Accra Mall",
    location: "Accra, Ghana",
    image:
      "https://robohash.org/6abffbf2e22be3dbfcea542cb0de11f4?set=set4&bgset=&size=400x400",
    time: "November 2023"
  },
  {
    feedBack:
      "Eddievolt’s charging station was a lifesaver during my busy shopping day at Makola Market. The lockers kept my phone secure, and I could continue my business without worrying about my battery. I can't wait to see these stations in more places!",
    name: "Ama Ofori, Pilot User at Makola Market",
    location: "Accra Ghana",

    image:
      "https://robohash.org/60eabbb05fe00d030fddcd1b78e4cc68?set=set4&bgset=&size=400x400",
    time: "November 2023"
  },
  {
    feedBack:
      "As someone who relies heavily on my smartphone for my daily operations, the Eddievolt charging station was a game changer. I was able to charge my phone while working at the market, without the fear of losing data. This service is going to be a hit!",
    name: "Kojo Asante, Pilot User and Small Business Owner",
    location: "Accra Ghana",

    image:
      "https://robohash.org/089ec47e3c1ae9de179e5765a1ee9067?set=set4&bgset=&size=400x400",
    time: "November 2023"
  },
  {
    feedBack:
      "When I first heard about Eddievolt, I knew this was a solution that could make a real impact in Ghana. After seeing the pilot and hearing the feedback from users, I’m convinced this company is on the path to success. The innovation and security behind the product are top-notch.",
    name: "Kekeli Newmann, Early-Stage Investor",
    location: "Accra Ghana",

    image:
      "https://robohash.org/77c8cba8b2e85fa3e47d40076ecd9545?set=set4&bgset=&size=400x400",
    time: "November 2023"
  },
  {
    feedBack:
      "Charging my phone has always been a hassle at the market, but Eddievolt changed that. Now, I can leave my phone to charge securely and get on with my day. This is exactly what we need in our busy urban areas!",
    name: "Efua Adjei, Market Trader and Pilot User",
    location: "Accra Ghana",

    image:
      "https://robohash.org/5572416e70dbaae417fa7a26982ab544?set=set4&bgset=&size=400x400",
    time: "November 2023"
  },
  {
    feedBack:
      "Eddievolt impressed me with its innovative approach to mobile charging. The blend of sustainability, convenience, and security is something that sets it apart from anything else in the market. I'm excited to see how this will revolutionize the way we charge our devices in Ghana.",
    name: "Dr. Kofi Owusu, Technology Enthusiast and Investor",
    location: "Accra Ghana",

    image:
      "https://robohash.org/0a198da1a10c5dd4a1edbcf325c3c8ac?set=set4&bgset=&size=400x400",
    time: "November 2023"
  },
  {
    feedBack:
      "As a student, I'm constantly on the go, and my phone is my lifeline. Eddievolt’s charging station at the university was a game-changer. I could charge my phone securely while studying, without worrying about finding a plug or carrying my charger everywhere. This service is invaluable!",
    name: "Patience Duvor, Student and Pilot User at University of Ghanay",
    location: "Accra Ghana",

    image:
      "https://robohash.org/5cf6cddde0b6f0aef0d1310e4567a4c4?set=set4&bgset=&size=400x400",
    time: "November 2023"
  }
];

export default function Testimonials({ page }) {
  const initialCount = 3;
  const [visibleCount, setVisibleCount] = useState(initialCount);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleViewMore = () => {
    setIsAnimating(true);
    setTimeout(() => {
      const nextCount = visibleCount + 3;
      setVisibleCount(
        nextCount > testimonialData.length ? testimonialData.length : nextCount
      );
      setIsAnimating(false);
    }, 500);
  };

  const handleCloseAll = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setVisibleCount(initialCount);
      setIsAnimating(false);
    }, 500); // Duration matches CSS transition time
  };

  const itemsToShow = testimonialData.slice(0, visibleCount);

  return (
    <div className="testimonials-container">
      <ul className={`testimonials-list ${isAnimating ? "" : "show"}`}>
        {itemsToShow.map((item, index) => (
          <li
            key={index}
            className={`testimonial-item ${isAnimating ? "" : "show"}`}
          >
            <p className="feedback-text">"{item.feedBack}"</p>
            <div className="user-info">
              <img className="user-image" src={item.image} alt="user profile" />
              <div className="user-details">
                <p className="user-name">{item.name}</p>
                <p className="feedback-time">
                  {item.time} {item.location}
                </p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="actions-container">
        {visibleCount < testimonialData.length && (
          <button className="action-btn view-more-btn" onClick={handleViewMore}>
            View More <span>&#9662;</span>
          </button>
        )}
        {visibleCount > initialCount && (
          <button className="action-btn close-all-btn" onClick={handleCloseAll}>
            Close All <span>&#9652;</span>
          </button>
        )}
      </div>
      <Shapes />
    </div>
  );
}

