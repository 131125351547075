import React, { useRef, useState } from "react";
import "./Contact.css";
import Shapes from "../components/Shape";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaTwitter,
  FaPhoneAlt,
  FaMapMarkerAlt,
  FaEnvelope,
  FaUser,
  FaTag,
  FaLinkedin,
} from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { BaseUrl } from "../components/BaseUrl";

const Contact = () => {
  const contactRef = useRef(null);
  const [formData, setFormData] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });
  const [responseMessage, setResponseMessage] = useState(""); // State for response message
  const [isError, setIsError] = useState(false); // State for error flag

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BaseUrl}/submitContact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setResponseMessage("Your message has been sent successfully!");
        setIsError(false); // Set error flag to false on success
        setFormData({
          name: "",
          subject: "",
          email: "",
          message: "",
        });
      } else {
        const errorData = await response.json();
        console.error("Error Response:", errorData);
        setResponseMessage("Failed to send your message. Please try again.");
        setIsError(true); // Set error flag to true on failure
      }
    } catch (error) {
      console.error("Error:", error);
      setResponseMessage("An error occurred. Please try again later.");
      setIsError(true); // Set error flag to true on exception
    }
  };

  return (
    <div id="contact" ref={contactRef} className="contact-container">
      <Shapes />
      <div className="contact-details">
        <div className="contact-form-container">
          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">
              {" "}
              <FaUser className="contact-icon" />
              Full Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Enter your full name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="subject">
              {" "}
              <FaTag className="contact-icon" />
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              placeholder="What is your subject"
              value={formData.subject}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="email">
              <FaEnvelope className="contact-icon" />
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Enter your email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="message">
              <FaMessage className="contact-icon" />
              Message
            </label>
            <textarea
              id="message"
              name="message"
              placeholder="Your message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
            <button type="submit">Send Message</button>
            {/* Inline message display */}
            <span
              style={{
                color: isError ? "red" : "green",
                marginTop: "10px",
                display: "block",
              }}
            >
              {responseMessage}
            </span>
          </form>
        </div>
        <div className="contact-content">
          <h2 className="featured-heading">Contact Us</h2>
          <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
            <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
              <p className="contact-item">
                <FaMapMarkerAlt className="contact-icon" />
                <span style={{ width: "90%" }}>
                  Spintex Road Adjacent Canwest Limited, Accra, Ghana.
                </span>
              </p>
              <p className="contact-item">
                <FaPhoneAlt className="contact-icon" />
                <span style={{ width: "90%" }}>
                  Call us at (+233) 241310753 / 545160073
                </span>
              </p>
              <p className="contact-item">
                <FaEnvelope className="contact-icon" />
                <span style={{ width: "90%" }}>
                  Email us at
                  <a href="mailto:contact@eddievolt.com">
                    {" "}
                    contact@eddievolt.com
                  </a>
                </span>
              </p>
              <p className="contact-item">
                <FaEnvelope className="contact-icon" />
                <span style={{ width: "90%" }}>
                  <a href="mailto:investors@eddievolt.com">
                    {" "}
                    investors@eddievolt.com
                  </a>{" "}
                  For investor-specific questions
                </span>
              </p>
            </div>
            <div className="social-icons">
              <Link
                to="https://www.linkedin.com/company/eddievolt/"
                className="social-icon"
              >
                <FaLinkedin />
              </Link>

              <Link
                to="https://www.youtube.com/@eddievolt"
                className="social-icon"
              >
                <FaYoutube />
              </Link>
              <Link
                to="https://www.instagram.com/eddievolt"
                className="social-icon"
              >
                <FaInstagram />
              </Link>
              <Link to="https://twitter.com/eddievolt_" className="social-icon">
                <FaTwitter />
              </Link>
              <Link
                to="https://www.facebook.com/eddievoltglobal"
                className="social-icon"
              >
                <FaFacebook />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <iframe
          title="map"
          width="98%"
          height="400"
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Spintex%20Road,%20Opposite%20CanWest%20Limited,%20Accra.%20Ghana+(My%20Business%20Name)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        >
          <a href="https://www.gps.ie/">gps systems</a>
        </iframe>
      </div>
    </div>
  );
};

export default Contact;
