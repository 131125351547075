import React from "react";
import "./Home.css";
import { NavLink } from "react-router-dom";
import FeaturedSection from "../components/Featured";
import Testimonials from "../components/Testimonials";

function Home() {
  return (
    <div className="home-container">
      <div className="content">
        <div className="small-content">
          <div className="heading-container">
            <div className="text-section">
              <h1 className="heading">
                Powering a Sustainable Future – Secure and Eco-Friendly Charging
                Solutions for Ghana and Beyond
              </h1>
              <p className="featured-description description">
                Join us in revolutionizing mobile charging with our innovative,
                solar-powered charging stations featuring integrated advertising
                displays.
              </p>
              <div className="home-buttons">
                <NavLink to={"./Pre-Order"}>
                  <button className="pre-order-button">
                    Pre-Order Your Charging Station
                  </button>
                </NavLink>
                <NavLink to={"./investors"}>
                  <button className="pre-Invest-button">
                    Invest in Eddievolt
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
          <div className="image-section">
            <img
              src={require("../images/eddievolt-charging-station.png")}
              alt="Description"
              className="image"
            />
          </div>
        </div>

        <div className="additional-squares">
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
          <div className="square"></div>
        </div>
        <div className="wave"></div>
      </div>

      <div className="hanging-mission-section">
        <h2 className="mission-heading">Our Mission</h2>
        <p className="mission-paragraph">
          At Eddievolt, our mission is to deliver secure, reliable, and
          sustainable charging solutions that enhance connectivity and promote
          environmental stewardship across Ghana, while providing valuable
          advertising opportunities for businesses.
        </p>
        <div className="shape small top-left"></div>
        <div className="shape medium bottom-right"></div>
        <div className="shape large bottom-left"></div>
      </div>
      <FeaturedSection />
      <section class="testimonials-section">
        <div className="test-title-des">
          <h2 class="featured-heading">Testimonials</h2>
          <p class="subtitle">What Our Users and Investors Are Saying</p>
        </div>
        <div class="testimonials-container">
          <Testimonials page="testimonials" />
        </div>
      </section>
    </div>
  );
}

export default Home;

