import React from "react";
import { MdOutlineDateRange } from "react-icons/md";
import "./Timeline.css";

const certificates = [
  {
    year: "2022",
    description:
      "Eddievolt is founded and begins development of its innovative charging stations."
  },
  {
    year: "2023",
    description: "Successful pilot with 200+ pilot users."
  },
  {
    year: "2024",
    description:
      "Founder Institute Alumni - Preparing for commercial launch and scaling operations, with two patents pending in trademark and design and five pre-financed charging stations."
  },
  {
    year: "2025",
    description:
      "Expansion into more high-traffic areas nationwide and entry into neighboring countries."
  }
];

const Timeline = () => {
  const CertificateList = ({ data }) => (
    <div className="certificate-timeline-item">
      <div className="certificate-timeline-item-content">
        <time>
          {data.year} <MdOutlineDateRange style={{ fontSize: 17 }} />
        </time>
        <p>{data.description}</p>
      </div>
    </div>
  );

  return (
    <div className="certificate-timeline-container">
      {certificates.map((data, idx) => (
        <CertificateList data={data} key={idx} />
      ))}
    </div>
  );
};

export default Timeline;

